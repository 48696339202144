
import { defineComponent } from "vue";
import { auth, db, deleteTrack } from "@/firebase";
import { Tracks } from "@app/shared";
import { mapGetters } from "vuex";
import { ElMessage } from "element-plus";
import firebase from "firebase";

export default defineComponent({
  name: "Users",
  data() {
    return {
      tracks: [],
      search: "",
    };
  },
  methods: {
    viewGames(row: Tracks.TrackInformation) {
      this.$router.push({ name: "SingleLevel", params: { id: row.id } });
    },
    async handleDeletion(id?: string) {
      if (!id) return;
      const res = await deleteTrack({ levelId: id });
      if (!res.data.success) {
        switch (res.data.error) {
          case "track_has_games":
            ElMessage({
              type: "error",
              message: this.$t("levels.errors.deletion_cause_game"),
            });
            break;
          default:
            ElMessage({
              type: "error",
              message: this.$t("levels.errors.deletion"),
            });
            break;
        }
      } else {
        ElMessage({
          type: "success",
          message: this.$t("levels.success.deletion"),
        });
      }
    },
    formatLocation(location: Array<string>): string {
      return (
        "https://www.google.com/maps/search/?api=1&query=" +
        location[0].split(",")[0] +
        "%2C" +
        location[0].split(",")[1]
      );
    },
    formatTimeStamp(
      row: number,
      col: number,
      timestamp: firebase.firestore.Timestamp
    ): string {
      if (timestamp == undefined) {
        return "-";
      }
      return timestamp.toDate().toLocaleDateString("fi-FI");
    },
  },
  computed: {
    ...mapGetters(["isAdmin"]),
  },
  watch: {
    isAdmin: {
      immediate: true,
      handler(isAdmin) {
        if (auth.currentUser)
          this.$bind(
            "tracks",
            isAdmin
              ? db.collection("tracks")
              : db
                  .collection("tracks")
                  .where("author", "==", auth.currentUser?.email)
          );
      },
    },
  },
});


import { defineComponent } from "vue";
import { auth, db, deleteSession } from "@/firebase";
import { mapGetters } from "vuex";
import { ElMessage } from "element-plus";
import firebase from "firebase";

export default defineComponent({
  name: "Users",
  data() {
    return {
      games: [],
      search: "",
    };
  },
  methods: {
    async handleDeletion(code?: string) {
      if (!code) return;
      const res = await deleteSession({ gameCode: code });
      if (!res.data.success) {
        ElMessage({
          type: "error",
          message: this.$t("game.errors.deletion"),
        });
      } else {
        ElMessage({
          type: "success",
          message: this.$t("game.success.deletion"),
        });
      }
    },
    formatTimeStamp(
      row: number,
      col: number,
      timestamp: firebase.firestore.Timestamp
    ): string {
      if (timestamp == undefined) {
        return "-";
      }
      return timestamp.toDate().toLocaleDateString("fi-FI");
    },
    formatClass(row: number, col: number, value: string) {
      return value.substr(4, 3);
    },
    formatSet(row: number, col: number, value: string) {
      return value.slice(-1);
    },
  },
  computed: {
    ...mapGetters(["isAdmin"]),
  },
  watch: {
    isAdmin: {
      immediate: true,
      handler(isAdmin) {
        if (auth.currentUser)
          this.$bind(
            "games",
            isAdmin
              ? db.collection("gamesessions")
              : db
                  .collection("gamesessions")
                  .where("uid", "==", auth.currentUser?.uid)
          );
      },
    },
  },
});
